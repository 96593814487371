import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  const _component_van_number_keyboard = _resolveComponent("van-number-keyboard");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_router_view), _createVNode(_component_van_nav_bar, {
    "safe-area-inset-top": ""
  }), _createVNode(_component_van_number_keyboard, {
    "safe-area-inset-bottom": ""
  })]);
}