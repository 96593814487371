import axios from "axios";
import router from "@/router"

const redirectToLogin = () => {
  const currentRoute = router.currentRoute;
  console.log('redirectToLogin, current path: ', currentRoute.path);
  if (currentRoute.path !== '/login') {
    router.push({
      path: '/login',
      query: currentRoute.query,
    });
  }
};


//取回cookie
function getCookie(c_name)
{
  if (document.cookie.length>0)
  {
    var c_start=document.cookie.indexOf(c_name + "=")
    if (c_start!=-1)
    {
      c_start=c_start + c_name.length+1
      var c_end=document.cookie.indexOf(";",c_start)
      if (c_end==-1) c_end=document.cookie.length
      return unescape(document.cookie.substring(c_start,c_end))
    }
  }
  return ""
}
class HttpRequest {

  instance;
  loading;
  wxUtil;
  static defaults;

  constructor(config) {


    config = Object.assign({
        baseURL: '/api/v1',
      timeout: 50000,
      headers: {
        'Cache-Control': 'no-cache'
      }
    }, config? config: {})

    this.instance = axios.create(config);
    // 请求前拦截
    this.instance.interceptors.request.use(
      async (config) => {
        let token = sessionStorage.getItem('token')
        const lToken = getCookie('token')
        if (lToken && lToken != 'undefined'){
          token=lToken
            console.log('http interceptor lToken set', token)
        }else {

          console.log('lToken interceptor', lToken)
        }
        if(token && config.headers && token != 'undefined') {
          config.headers['Authorization2'] = 'Bearer ' + token
          console.log('http interceptor token set', token)
        }else {
            console.log('http interceptor ignore token2')
        }
        config.params = {...config.params, t: Date.now()}
        return config;
      },
      (err) => {
        return err;
      }
    );
    // 响应后的拦截
    this.instance.interceptors.response.use(
      (res) => {
        console.log('http interceptor response', res)
        this.loading?.close();
        if (res.status >= 200 && res.status < 300) {
          //"application/octet-stream;charset=utf-8"
          let ct = res.headers['content-type']
          if((ct + '').indexOf('octet-stream') > -1) {
            return res
          }
          return res.data
        } else if (res.status === 400) {
          //  微信扫码登录 如果用户未绑定手机 则返回400
          return res.data
        } else if (res.data.status === 401 || res.data.status === 403) {
          // token过期后 重新登录
          // router.replace({
          //   name: 'login'
          // })
          console.log("request 403")
          this.setCookie("is403","1")
          this.setCookie("token","")
          localStorage.clear()
          sessionStorage.clear()
          redirectToLogin()
          return res.data
        } else {
          console.log('res.data.status: ' + res.data.status)
        }
      },
      (err) => {
        console.log('http interceptor error', err)
        console.log('err.response status: ', err.response.status)
        sessionStorage.removeItem('token')
        localStorage.clear()
        sessionStorage.clear()
        switch (err.response.status) {
          case 401:
            // redirectToLogin();
            return err.response.data;
          case 403:

            console.log("request 403")
            this.setCookie("is403","1")
            this.setCookie("token","")
            localStorage.clear()
            sessionStorage.clear()
            redirectToLogin();
            break;
          case 400:
            // ElMessage.error(err.response.data.message);
            break;
          case 404:
            // ElMessage.error(err.response.data.message);
            break;
          case 500:
            // ElMessage.error(err.response.data.message);
            break;
          case 502:
            break;
        }
        return err;
      }
    );
  }

  getQueryVariable(variable) {
    //获取当前URL中的查询字符串部分（即问号后面的部分）
    var query = window.location.search.substring(1);
    //把查询字符串按照&符号分割成数组
    var vars = query.split("&");
    //遍历数组中的每个元素
    for (var i = 0; i < vars.length; i++) {
      //把当前元素按照=符号分割成键和值
      var pair = vars[i].split("=");
      //如果当前元素的键等于参数名，则返回对应的值
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    //如果没有找到匹配的参数名，则返回false
    return false;
  }
//取回cookie
  getCookie(c_name)
  {
    if (document.cookie.length>0)
    {
      var c_start=document.cookie.indexOf(c_name + "=")
      if (c_start!=-1)
      {
        c_start=c_start + c_name.length+1
        var c_end=document.cookie.indexOf(";",c_start)
        if (c_end==-1) c_end=document.cookie.length
        return unescape(document.cookie.substring(c_start,c_end))
      }
    }
    return ""
  }
  setCookie(c_name, value) {
    const expiredays = 31
    const exdate = new Date()
    exdate.setDate(exdate.getDate() + expiredays)
    document.cookie = c_name + "=" + escape(value) +
        ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString())
  }

  request (config) {
    return new Promise((resolve, reject) => {
      this.instance
        .request(config)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          return err;
        });
    });
  }

  download(config) {

    return this.request({ ...config, method: 'post', responseType: 'blob' }).then((res) => {

      // fileDownload(res, "file.xlsx")
      let blob = new Blob([res.data],{type: res.headers['content-type']});
      // 创建新的URL并指向File对象或者Blob对象的地址
      const blobURL = window.URL.createObjectURL(blob)
      // 创建a标签，用于跳转至下载链接
      const tempLink = document.createElement('a')
      tempLink.style.display = 'none'
      tempLink.href = blobURL
      // ${params.filePath}
      const contentDisposition = res.headers['content-disposition'] || `attachment;filename=out.xlsx`;
      tempLink.setAttribute('download', decodeURI(contentDisposition.split(';')[1].split('=')[1]))
      // 兼容：某些浏览器不支持HTML5的download属性
      if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank')
      }
      // 挂载a标签
      document.body.appendChild(tempLink)
      tempLink.click()
      document.body.removeChild(tempLink)
      // 释放blob URL地址
      window.URL.revokeObjectURL(blobURL)
      return res;
    });
  }

  post(config) {
    return this.request({ ...config, method: 'post' })
  }

  get(config) {
    return this.request({ ...config, method: 'get' });
  }

  delete(config) {
    return this.request({ ...config, method: 'delete' });
  }

  put (config) {
    return this.request({ ...config, method: 'put' });
  }
}
export default HttpRequest;
