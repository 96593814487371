import { createRouter, createWebHistory } from 'vue-router'
import IndexPage from '../page/IndexPage.vue'
import HomePage from '../page/HomePage.vue'
import LoginPage from '../page/LoginPage.vue'
import SignUpPage from '../page/SignUpPage.vue'
import AgreementPage from '../page/AgreementPage.vue'
import ContractsPage from '../page/ContractsPage.vue'
import FriendCyclePage from "@/page/FriendCyclePage.vue";
import FriendCyclePage3 from "@/page/FriendCyclePage3.vue";
import PayPage from "@/page/PayPage.vue";
import CenterPage from "@/page/centerPage.vue";
import FriendCyclePage2 from "@/page/FriendCyclePage2.vue";
import DetailPage from "@/page/DetailPage.vue";




const routes = [
  {
    path: '/',
    name: 'index',
    component: IndexPage,
    meta: { title: '车速摇' }
  },
  {
    path: '/home',
    name: 'home',
    component: HomePage,
    meta: { title: '车速摇' }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: { title: '车速摇' }
  },
  {
    path: '/detail',
    name: 'detail',
    component: DetailPage,
    meta: { title: '车速摇' }
  },
  {
    path: '/sign-up',
    name: 'signUp',
    component: SignUpPage,
    meta: { title: '车速摇' }
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: AgreementPage,
    meta: { title: '车速摇' }
  },

  {
    path: '/contracts',
    name: 'contracts',
    component: ContractsPage,
    meta: { title: '车速摇' }
  },
  {
    path: '/center',
    name: 'center',
    component: CenterPage,
    meta: { title: '车速摇' }
  },
  {
    path: '/pay/',
    name: 'pay',
    component: PayPage,
    meta: { title: '车速摇-支付页面' }
  },
  {
    path: '/friendCycle',
    name: 'friend-cycle',
    component: FriendCyclePage,
    meta: { title: '车速摇' }
  },
  {
    path: '/friendCycle3',
    name: 'friend-cycle3',
    component: FriendCyclePage3,
    meta: { title: '车速摇' }
  },
  {
    path: '/friendCycle2',
    name: 'friend-cycle2',
    component: FriendCyclePage2,
    meta: { title: '车速摇' }
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach(async (to) => {
  if (to.meta.title) { // 判断是否有标题
    document.title = to.meta.title;
  }
});
export default router
