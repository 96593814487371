import Request from '@/net/request'
import {closeToast, showConfirmDialog, showLoadingToast, showNotify} from "vant";
import wx from "weixin-js-sdk";

class WxUtil {
    request

    constructor() {
        this.request = new Request()
    }

    getQueryVariable(variable) {
        //获取当前URL中的查询字符串部分（即问号后面的部分）
        var query = window.location.search.substring(1);
        //把查询字符串按照&符号分割成数组
        var vars = query.split("&");
        //遍历数组中的每个元素
        for (var i = 0; i < vars.length; i++) {
            //把当前元素按照=符号分割成键和值
            var pair = vars[i].split("=");
            //如果当前元素的键等于参数名，则返回对应的值
            if (pair[0] == variable) {
                return pair[1];
            }
        }
        //如果没有找到匹配的参数名，则返回false
        return false;
    }

    getOpenId(callback) {
        const code = this.getQueryVariable("code")
        const state = this.getQueryVariable("state")
        const openid = this.getCookie("openid");
        const codeType = this.getCookie("codeType")
        console.log("getOpenId codeType:", codeType, "code:", code, "state:", state, "openid:", openid)
        if (code && state && codeType === "base") {
            this.request.post({
                url: '/n/app-api/member/auth/social-login',
                data: {
                    code: code,
                    state: state,
                    type: 31
                }
            }).then(r => {
                console.log("v1", r)
                if (r.status === 0) {
                    sessionStorage.setItem("openid", r.data)
                    this.request.setCookie("openid", r.data)
                    if (callback) {
                        callback(r.data)
                    }
                }
            })
        } else {

//            showNotify({type: 'danger', message: "获取code中...."});
            this.getWxCode()
            console.log("redirect uri")
        }
        return
    }

    updateWxUserInfo(callback) {
        const code = this.getQueryVariable("code")
        const state = this.getQueryVariable("state")
        const codeType = this.getCookie("codeType")
        const userinfoUpdated = sessionStorage.getItem("userinfoUpdated")
        console.log("updateWxUserInfo codeType:", codeType)
        if (code && state) {
            this.request.post({
                url: '/n/app-api/member/auth/update-wx-userinfo',
                data: {
                    code: code,
                    state: state,
                    type: 32
                }
            }).then(r => {
                console.log(" updateWxUserInfo v1", r)
                if (r.status === 0) {
                    sessionStorage.setItem("userinfoUpdated", r.data)
                    if (callback) {
                        callback(r.data)
                    }
                }
            })
        } else if (!userinfoUpdated) {
            this.getWxCodeByUserinfo()
            console.log("redirect uri")
        }

        return
    }

    checkUserInfo(callback) {

        this.request.post({
            url: '/n/app-api/member/auth/get-userinfo'
        }).then(r => {
            console.log("checkUserInfo v1", r)
            if (r.status === 0) {
                if (!r.data.avatarUrl) {
                    this.updateWxUserInfo()
                }

                if (callback) {
                    callback(r.data)
                }
            }
        })
    }

    setCookie(c_name, value) {
        const expiredays = 31
        const exdate = new Date()
        exdate.setDate(exdate.getDate() + expiredays)
        document.cookie = c_name + "=" + escape(value) +
            ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString())
    }

//取回cookie
    getCookie(c_name) {
        if (document.cookie.length > 0) {
            var c_start = document.cookie.indexOf(c_name + "=")
            if (c_start != -1) {
                c_start = c_start + c_name.length + 1
                var c_end = document.cookie.indexOf(";", c_start)
                if (c_end == -1) c_end = document.cookie.length
                return unescape(document.cookie.substring(c_start, c_end))
            }
        }
        return ""
    }

    getWxCode() {
        this.setCookie("codeType", "base")
        const uri = window.location.href;
        this.request.get({
            url: '/n/app-api/member/auth/social-auth-redirect?type=31&redirectUri=' + uri
        }).then(r => {
            console.log(r)
            if (r.status === 0)
                window.location.href = r.data
        })
    }

    getWxCodeByUserinfo() {
        this.setCookie("codeType", "userinfo")
        const uri = window.location.href;
        this.request.get({
            url: '/n/app-api/member/auth/social-auth-redirect?type=32&redirectUri=' + uri
        }).then(r => {
            console.log(r)
            if (r.status === 0)
                window.location.href = r.data
        })
    }

    getcheckStatus(callack, payOrderId) {
        this.request.post({
            url: '/n/app-api/trade/order/find-status',
            data: {
                payOrderId: payOrderId,
                merchantOrderId: "payOrderId"
            }
        }).then(r => {
            if (callack) {
                callack(r)
            }
        })
        showLoadingToast({
            message: '加载中...',
            forbidClick: true,
        });
    }

    pay() {
        showNotify({type: 'danger', message: "会员到期，正在发起续费支付"});
        this.topay((msg, payOrderId) => {
            if (msg === "success") {
                this.checkStatus(payOrderId)
            }
        })
    }

    confirmPay(title, msg) {
        showConfirmDialog({
            title: title || '会员到期.',
            message:
                msg || '是否支付99元/月会员费续期.',
        })
            .then(() => {
                // on confirm
                location.href = "/pay/";
            })
            .catch(() => {
                // on cancel
            });
    }

    updThreePoint2() {
        this.updThreePoint('车速瑶','内容介绍','https://owl-hl.oss-cn-shanghai.aliyuncs.com/visitor3.png')
    }
    updThreePoint(titleV, descV, imgV) {
        console.log(imgV)
        const uri = window.location.href;
        //showNotify({type: 'danger', message:uri});
        this.request.post({
            url: '/n/open-api/rights/jssdk/signature2?url=' + uri,
            data: {}
        }).then(res => {
            console.log(res)
            const data = res.data
            if (!data) {
                return
            }

            wx.config({
                debug: false, // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false,
                appId: data.appId, // 必填，公众号的唯一标识
                timestamp: data.timestamp, // 必填，生成签名的时间戳
                nonceStr: data.nonceStr, // 必填，生成签名的随机串
                signature: data.signature, // 必填，签名
                jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage', 'onMenuShareTimeline', 'onMenuShareQQ', 'onMenuShareQZone'] // 必填，需要使用的JS接口列表
            })
            wx.ready(() => {

                wx.onMenuShareTimeline({
                    title: titleV, // 分享标题
                    link: uri, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: imgV, // 分享图标
                    success: function () {
                        // 设置成功
                        console.log("set success")
                    }
                })
                wx.updateAppMessageShareData({
                    title: titleV, // 分享标题
                    desc: "sdfs", // 分享描述
                    link: uri, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: imgV, // 分享图标

                    success: function () {
                        // 设置成功
                        console.log("set success")
                    }
                })
                /*wx.onMenuShareTimeline({
                    title: '车速瑶', // 分享标题
                    link: 'http://csy.myqd.vip/friendCycle', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: 'https://owl-hl.oss-cn-shanghai.aliyuncs.com/visitor3.png', // 分享图标
                    type: 'link', // 分享类型,music、video或link，不填默认为link
                    success: function () {
                        // 用户点击了分享后执行的回调函数
                        console.log("set success")
                    }
                });*/

            });
        })
    }

    checkStatus(payOrderId) {
        showLoadingToast({
            message: '加载中...',
            duration: 0,
            forbidClick: true,
        });
        this.getcheckStatus((r) => {
            closeToast()
            if (r.status === 0 && !r.data) {

                this.checkStatus(payOrderId)
            } else if (r.data) {
                showNotify({type: 'success', message: "支付成功.."});
                //router.push({path: "/friendCycle"})
                location.href = "/friendCycle";

                location.href = "/center";
            } else {
                showNotify({type: 'danger', message: r.msg});
            }

        }, payOrderId)
    }

    topay(callback) {

        const openid = this.getCookie("openid");
        console.log("openid", openid)

        if (!openid) {
            showNotify({type: 'danger', message: "未获得openid"});
            this.getWxCode()
            if (callback) {
                callback("fail")
            }
        }
        return this.request.post({
            url: '/n/app-api/trade/order/create',
            data: {
                "cartIds": [],
                "account": openid,
                "items": [
                    {
                        "spuId": "5",
                        "skuId": "7",
                        "count": 1
                    }
                ],
                "type": 4,
                "subType": 7
            }
        }).then(r => {
            if (r.status === 0) {

                const payOrderId = r.data.payOrderId;
                return this.request.post({
                    url: '/n/app-api/pay/order/submit',
                    data: {
                        id: r.data.payOrderId,
                        channelCode: "wx_pub",
                        openid: openid
                    }
                }).then(r => {
                    if (r.status === 0) {
                        console.log(r)
                        const data = r.data.invokeResponse


                        //showNotify({type: 'danger', message: "当前支付页面："+uri});
                        wx.config({
                            debug: false, // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false,
                            appId: data.appId, // 必填，公众号的唯一标识
                            timestamp: data.timeStamp, // 必填，生成签名的时间戳
                            nonceStr: data.nonceStr, // 必填，生成签名的随机串
                            signature: data.paySign, // 必填，签名
                            jsApiList: ['chooseWXPay', 'checkJsApi'] // 必填，需要使用的JS接口列表
                        })
                        wx.ready(() => {
                            /* 微信支付 */
                            wx.chooseWXPay({
                                timestamp: data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                                nonceStr: data.nonceStr, // 支付签名随机串，不长于 32 位
                                package: data.packageValue, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                                signType: data.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                                paySign: data.paySign, // 支付签名
                                success: function () {
                                    // 前端判断返回方式，微信团队郑重提示：不保证绝对可靠，切记！
                                    if (callback) {
                                        callback("success", payOrderId)
                                    }
                                },

                            });
                        });


                    } else {
                        showNotify({type: 'danger', message: r.msg});
                        if (callback) {
                            callback("fail")
                        }
                    }
                })
            } else {
                showNotify({type: 'danger', message: r.msg});
                if (callback) {
                    callback("fail")
                }
            }
        })
    }
}

export default WxUtil;