import "core-js/modules/es.array.push.js";
import { onMounted, ref } from 'vue';
import Request from '@/net/request';
import { useRouter } from 'vue-router';
import { showNotify } from 'vant';
import WxUtil from "@/util/WxUtil";
export default {
  setup() {
    const request = new Request();
    const show_overlay = ref(false);
    const username = ref('');
    const password = ref('');
    const showLoad = ref(false);
    const router = useRouter();
    const wxUtil = new WxUtil();
    const yzmcode = ref('');
    const lToken = wxUtil.getCookie('token');
    const retry = wxUtil.getCookie('retry');
    const is403 = request.getCookie("is403");
    const time = ref(-1);
    const ShowDownTime = ref("发送验证码");
    const disableSmsBtn = ref(false);
    const timeRemaining = () => {
      setInterval(() => {
        time.value--;
        if (time.value < 1) {
          disableSmsBtn.value = false;
          clearInterval(timeRemaining);
          ShowDownTime.value = "发送验证码";
        } else {
          ShowDownTime.value = "(" + time.value + ")";
        }
      }, 1000);
    };
    const sendSms = () => {
      time.value = 59;
      disableSmsBtn.value = true;
      request.post({
        url: '/n/app-api/rights/user/sendLoginMobileCode?mobile=' + username.value,
        data: {
          mobile: username.value
        }
      }).then(r => {
        if (r.status == 0) {
          timeRemaining();
          showNotify({
            type: 'success',
            message: '验发送证码成功！'
          });
        } else {
          showNotify({
            type: 'danger',
            message: r.msg
          });
        }
      });
    };
    console.log("login retry", lToken, retry);
    if (lToken && retry !== "1") {
      wxUtil.setCookie('token', lToken);
      wxUtil.setCookie('retry', "1");
      router.push({
        path: "/friendCycle"
      });
    } else if (is403 === "1") {
      console.log("clear all cache");
      localStorage.clear();
      sessionStorage.clear();
      request.setCookie("token", "");
    }
    const login = () => {
      showLoad.value = true;
      let openid = request.getCookie("openid");
      const qOpenid = wxUtil.getQueryVariable("openid");
      if (qOpenid && !openid) {
        openid = qOpenid;
      }
      return request.post({
        url: '/n/app-api/rights/user/rebindLogin?mobile=' + username.value + "&yzm=" + yzmcode.value + "&openid=" + openid,
        data: {
          mobile: username.value,
          yzm: yzmcode.value,
          openid: openid
        }
      }).then(r => {
        if (r.status === 0) {
          console.log('rebind ', r);
          router.push({
            path: "/friendCycle"
          });
        } else {
          showNotify({
            type: 'danger',
            message: r.msg
          });
        }
      });
    };
    const signUp = () => {
      router.push({
        path: "/sign-up"
      });
    };
    const onSubmit = values => {
      console.log('submit', values);
      login(values).then(res => {
        console.log(res);
      });
    };
    onMounted(() => {
      const request = new Request();
      let openid = request.getCookie("openid");
      const qOpenid = wxUtil.getQueryVariable("openid");
      if (qOpenid && !openid) {
        openid = qOpenid;
      }
      if (openid) {
        showLoad.value = true;
        request.get({
          url: '/y/openapi/loginByOpenid?openid=' + openid,
          data: {
            openid: openid
          }
        }).then(r => {
          if (r.code == 0) {
            let data = r.data;
            console.log('loginByOpenId setCookie v1 data: ', data);
            wxUtil.setCookie('token', data);
            sessionStorage.setItem('token', data);
            wxUtil.setCookie('retry', "2");
            request.setCookie("403", "2");
            router.push({
              path: "/friendCycle"
            });
          } else {
            showNotify({
              type: 'danger',
              message: r.message
            });
          }
        });
      } else {
        wxUtil.getOpenId(openid => {
          request.get({
            url: '/y/openapi/loginByOpenid',
            data: {
              openid: openid
            }
          }).then(r => {
            if (r.code == 0) {
              let data = r.data;
              console.log('loginByOpenId setCookie v1 data: ', data);
              wxUtil.setCookie('token', data);
              sessionStorage.setItem('token', data);
              wxUtil.setCookie('retry', "2");
              request.setCookie("403", "2");
              router.push({
                path: "/"
              });
            } else {
              showNotify({
                type: 'danger',
                message: r.message
              });
            }
          });
        });
      }
    });
    return {
      username,
      ShowDownTime,
      disableSmsBtn,
      sendSms,
      yzmcode,
      password,
      showLoad,
      onSubmit,
      show_overlay,
      signUp
    };
  }
};