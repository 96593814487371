import "core-js/modules/es.array.push.js";
import { ref, reactive, onMounted } from 'vue';
import Request from '@/net/request';
import { showNotify } from 'vant';
import { useRouter } from 'vue-router';
import WxUtil from "@/util/WxUtil";
import FooterPage from "@/page/FooterPage.vue";
export default {
  components: {
    FooterPage
  },
  setup() {
    const wxUtil = new WxUtil();
    const follow = ref('');
    const request = new Request();
    const show_overlay = ref(false);
    const show_qrcode = ref(false);
    const login_status = ref(false);
    const show_follower_picker = ref(false);
    const awake = ref(false);
    const login_status_timeout = ref(0);
    const username = ref('');
    const password = ref('');
    const router = useRouter();
    let timeout = ref(0);
    const expire_time = ref(0);
    const settings = reactive({
      t: false,
      follow: undefined
    });
    const is_awake_login = ref(false);
    const def_acc = reactive({
      deviceName: '',
      headUrl: ''
    });
    let loginQrCode = ref("");
    const setting_fav_fc_switch = ref(false);
    const exp_count_down_timer = ref(-1);
    const follower_columns = ref([]);
    const login_check_time = ref(0);
    const cn = ref(true);
    const set_exp_count_down = () => {
      exp_count_down_timer.value = setInterval(function () {
        if (expire_time.value > 0) {
          expire_time.value = expire_time.value - 1;
        } else {
          clearInterval(exp_count_down_timer.value);
        }
      }, 1000);
    };
    const toCenter = () => {
      router.push({
        path: "/center"
      });
    };
    const logout = () => {
      return request.post({
        url: '/y/auth/logout'
      }).then(r => {
        console.log('logout res: ', r);
        request.setCookie('retry', "1");
        if (r.code == 0) {
          sessionStorage.removeItem('token');
          // @ts-ignore
          router.push({
            path: "/login"
          });
        } else {
          showNotify({
            type: 'danger',
            message: r.message
          });
        }
      });
    };
    const load_wx_acc_def = () => {
      return request.get({
        url: '/y/wx-account/default',
        params: {}
      }).then(r => {
        if (r.code == 0) {
          sessionStorage.setItem('retry', "1");
          if (r.data) {
            console.log('r: ', r);
            Object.assign(def_acc, r.data);
            startCheckLoginStatus(100);
            load_settings().then(r => {
              console.log('load_settings done! ', r);
              // load_contracts()
            });
          } else {
            console.log('user default device not found');
          }
        } else if (r.code == 'ERR_BAD_REQUEST') {
          console.log('code != 0, ', r);
          // showNotify({ type: 'danger', message: r.message });
        } else {
          showNotify({
            type: 'danger',
            message: r.message
          });
        }
      });
    };
    const load_settings = () => {
      console.log('load_settings: ', def_acc);
      if (typeof def_acc.wxId == 'undefined') {
        return;
      }
      return request.get({
        url: `/y/user/settings/${def_acc.wxId}`,
        params: {}
      }).then(r => {
        if (r.code == 0) {
          console.log('r: ', r);
          Object.assign(settings, r.data);
          console.log('settings: ', settings);
        } else {
          showNotify({
            type: 'danger',
            message: r.message
          });
        }
      });
    };
    const init = wxAccountId => {
      return request.get({
        url: `/y/wx/login/init/${wxAccountId}`,
        params: {
          hb: true
        }
      }).then(r => {
        console.log('init result: ', r);
        startCheckLoginStatus(100);
      });
    };
    const user_info = () => {
      request.post({
        url: `/y/user/info`,
        data: {}
      }).then(r => {
        console.log('user info: ', r);
        if (r.code == 0) {
          let d = r.data;
          def_acc.deviceName = d.username + "'s iPad";
        }
      });
    };
    const startCheckLoginStatus = time => {
      if (typeof def_acc.wxId == 'undefined') {
        return;
      }
      clearTimeout(login_status_timeout.value);
      login_status_timeout.value = setTimeout(() => {
        return request.get({
          url: `/y/wx-account/login-status/${def_acc.wxId}`
        }).then(t => {
          if (t.code == 0) {
            // 状态已退出
            if (!t.data.status) {
              clearInterval(login_status_timeout.value);
              showNotify({
                type: 'warning',
                message: "微信已退出"
              });
              login_status.value = false;
              // lastlogin time
              if (!t.data.ls) {
                console.log('last login time: ', t.data.ls);
                awake.value = false;
              } else {
                console.log('last login time: ', t.data.ls);
                awake.value = true;
              }
            } else {
              login_status.value = true;
              startCheckLoginStatus(5000);
            }
          } else {
            console.log('login_status_itv: ', t);
            clearInterval(login_status_timeout.value);
            login_status.value = false;
          }
        });
      }, time);
    };
    const checkLogin = () => {
      return request.get({
        url: `/y/wx/login/check/${def_acc.id}`
      }).then(r => {
        console.log('checkLogin r: ', r);
        if (r.data && r.data.WxId) {
          def_acc.wxId = r.data.WxId;
          console.log('wxId: ', def_acc.wxId);
          init(def_acc.wxId).then(rd => {
            console.log('init result data: ', rd);
            if (def_acc.id) {
              load_wx_acc_def();
            }
          });
          showNotify({
            type: 'success',
            message: r.data.NickName + " 登录成功！"
          });
          show_qrcode.value = false;
          clearTimeout(timeout.value);
        } else if (r.data && r.data.status == 4) {
          showNotify({
            type: 'warning',
            message: "唤醒登录失败!"
          });
          show_qrcode.value = false;
          clearTimeout(timeout.value);
          if (is_awake_login.value) {
            // awake.value = true
            login_status.value = false;
            awake.value = false;
          }
        } else if (!r.data) {
          showNotify({
            type: 'warning',
            message: "登录超时!"
          });
          show_qrcode.value = false;
          clearTimeout(timeout.value);
        } else {
          console.log('checkLogin >>>: ', r);
          timeout.value = setTimeout(checkLogin, 3000);
          if (r.data) {
            if (expire_time.value == 0) {
              expire_time.value = r.data.expiredTime;
              login_check_time.value = r.data.timestamp;
            }
            if (login_check_time.value != r.data.timestamp) {
              expire_time.value = r.data.expiredTime;
            }
            if (exp_count_down_timer.value == -1) {
              set_exp_count_down();
            }
          }
        }
      });
    };
    const handleAwake = () => {
      return request.get({
        url: `/y/wx/login/awaken/${def_acc.id}`,
        params: {
          cn: cn.value
        }
      }).then(r => {
        console.log('awaken result: ', r);
        if (r.code == 0) {
          if (r.data) {
            is_awake_login.value = true;
            // timeout.value = setTimeout(checkLogin, 5000)
            checkLogin();
            showNotify({
              type: 'success',
              message: "操作成功，请检查手机微信客户端！"
            });
          } else {
            showNotify({
              type: 'danger',
              message: "操作失败，请扫码登录！"
            });
            is_awake_login.value = false;
            login_status.value = false;
            awake.value = false;
            onSubmit();
          }
        } else {
          showNotify({
            type: 'danger',
            message: r.message
          });
          is_awake_login.value = false;
          awake.value = false;
        }
      });
    };
    const handleLogout = () => {
      return request.get({
        url: `/y/wx/login/logout/${def_acc.wxId}`,
        params: {}
      }).then(r => {
        if (r.code == 0) {
          login_status.value = false;
          startCheckLoginStatus(100);
        }
      });
    };
    const on_settings_t_update = val => {
      console.log('on_settings_t_update', val);
      return request.get({
        url: `/y/user/settings/t/${def_acc.wxId}`,
        params: {
          val: val
        }
      }).then(r => {
        if (r.code == 0) {
          console.log('r: ', r);
          Object.assign(settings, r.data);
        } else {
          showNotify({
            type: 'danger',
            message: r.message
          });
        }
      });
    };

    // const load_contracts = () => {
    //   return request.get({
    //     url: `/wx-contract/list/${def_acc.wxId}?page=1&size=10000`,
    //     params: {}
    //   }).then(r => {
    //     console.log('load contracts: ', r)
    //     if(r.code == 0) {
    //       follower_columns.value = r.data.records.map((item) => {
    //         return {
    //           text: item.nickName,
    //           value: item.userName
    //         }
    //       })
    //       follower_columns.value.splice(0, 0, {text: '不跟随', value: ''})
    //       for(var i in follower_columns.value) {
    //         if(follower_columns.value[i].value == settings.follow) {
    //           follow.value = follower_columns.value[i].text
    //           break
    //         }
    //       }
    //     }
    //   })
    // }

    const set_follower = t => {
      console.log('set_follower: ', t);
      if (typeof def_acc.wxId == 'undefined') {
        return;
      }
      return request.get({
        url: `/y/user/settings/follow/${def_acc.wxId}`,
        params: {
          toWxId: t
        }
      }).then(r => {
        if (r.code == 0) {
          console.log('r: ', r);
          // Object.assign(settings, r.data)
        } else {
          //showNotify({ type: 'danger', message: r.message });
        }
      });
    };
    const onSubmit = () => {
      console.log('submit called');
      const getLoginQrCode = () => {
        let params = {
          deviceId: def_acc.deviceId,
          deviceName: def_acc.deviceName,
          osModel: def_acc.osModel,
          acquireProxy: false
        };
        if (def_acc.id) {
          params['wxAccountId'] = def_acc.id;
        }
        // if(f.supplyProxy) {
        //     params['supplyProxy'] = f.supplyProxy
        //     params['host'] = f.host
        //     params['port'] = f.port
        //     params['username'] = f.username
        //     params['password'] = f.password
        // }
        console.log('params: ', params);
        return request.post({
          url: '/y/wx/login/qrCode?cn=true',
          data: params
        });
      }; // end of
      getLoginQrCode().then(r => {
        console.log('res: ', r);
        if (r.code == 0) {
          loginQrCode.value = r.data.qrBase64;
          def_acc.uuid = r.data.uuid;
          def_acc.deviceId = r.data.deviceId;
          def_acc.id = r.data.wxAccountId;
          def_acc.qrExpireTime = r.data.qrExpireTime;
          show_qrcode.value = true;
          timeout.value = setTimeout(checkLogin, 5000);
          // eslint-disable-next-line no-empty
        } else if (r.code == 2) {
          wxUtil.confirmPay();
        } else {
          showNotify({
            type: 'danger',
            message: r.message
          });
        }
      });
    };
    onMounted(() => {
      const wxUtil = new WxUtil();

      //wxUtil.checkUserInfo()

      /*const uri = window.location.href;
       request.post({
        url: '/n/open-api/rights/jssdk/signature?url=' + uri
      }).then(r => {
        console.log("signature v1", r)
        if (r.status === 0) {
          console.log(r.data)c
          const data = r.data
          wx.config({
            debug: false, // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false,
            appId: data.appId, // 必填，公众号的唯一标识
            timestamp: data.timestamp, // 必填，生成签名的时间戳
            nonceStr: data.nonceStr, // 必填，生成签名的随机串
            signature: data.signature, // 必填，签名
            jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage', 'onMenuShareTimeline', 'onMenuShareQQ', 'onMenuShareQZone'] // 必填，需要使用的JS接口列表
          })
          wx.ready(() => {
            /!* 微信支付 *!/
            wx.updateAppMessageShareData({
              title: '邀请您来参加', // 分享标题
              desc: '来参加吧', // 分享描述
              link: 'https://api.nolanh.cn/sign-up?code=' + data.code, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: 'https://api.nolanh.cn/visitor.jpg', // 分享图标
              type: 'link',
              success: function () {
                // 设置成功
              }
            })
          });
         }
      })*/

      console.log("index");
      wxUtil.updThreePoint2();
      user_info();
      load_wx_acc_def();
    }); // onMounted

    return {
      playGuide: () => {
        window.location.href = require("@/assets/guide.mp4");
      },
      cn,
      show_follower_picker,
      follower_columns,
      follow,
      expire_time,
      logout,
      on_settings_t_update,
      load_settings,
      settings,
      awake,
      toCenter,
      login_status,
      show_qrcode,
      goBack: () => {},
      def_acc,
      username,
      password,
      onSubmit,
      show_overlay,
      loginQrCode,
      handleLogout,
      handleAwake,
      setting_fav_fc_switch,
      checkContracts: () => {
        router.push({
          path: "/contracts"
        });
      },
      checkFriendCycle: () => {
        router.push({
          path: "/friendCycle"
        });
      },
      on_follower_confirm: r => {
        console.log('onFollowerConfirm: ', r);
        show_follower_picker.value = false;
        set_follower(r.selectedOptions[0].value);
        if (r.selectedOptions[0].value == '') {
          follow.value = '';
          return;
        }
        follow.value = r.selectedOptions[0].text;
      }
    };
  }
};