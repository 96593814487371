import { ref } from "vue";
export default {
  setup() {
    const active = ref(true);
    /*const pathname=window.location.pathname
    console.log(pathname)
    const active1=ref(pathname==="/friendCycle")
    const active2=ref(pathname==="/")
    const active3=ref(false)
     active3.value=pathname==="/center"
    console.log(pathname==="/center")*/
    const icon1 = {
      active: 'https://owl-hl.oss-cn-shanghai.aliyuncs.com/static/ANN011.png',
      inactive: 'https://owl-hl.oss-cn-shanghai.aliyuncs.com/static/ANN01.png'
    };
    const icon2 = {
      active: 'https://owl-hl.oss-cn-shanghai.aliyuncs.com/static/ANN022.png',
      inactive: 'https://owl-hl.oss-cn-shanghai.aliyuncs.com/static/ANN02.png'
    };
    const icon3 = {
      active: 'https://owl-hl.oss-cn-shanghai.aliyuncs.com/static/ANN033.png',
      inactive: 'https://owl-hl.oss-cn-shanghai.aliyuncs.com/static/ANN03.png'
    };
    return {
      active,
      icon2,
      icon1,
      icon3
    };
  }
};