import "core-js/modules/es.array.push.js";
import { ref, reactive, onMounted } from 'vue';
import Request from '@/net/request';
import { showNotify } from 'vant';
import { useRouter } from 'vue-router';
import { showConfirmDialog } from 'vant';
export default {
  setup() {
    const list = ref([]);
    const uncommitted = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const pageNumber = ref(1);
    const follow = ref('');
    const request = new Request();
    const show_overlay = ref(false);
    const router = useRouter();
    const expire_time = ref(0);
    const checked_follow = {};
    const search = reactive({
      keywords: ''
    });
    const settings = reactive({
      t: false,
      follow: undefined
    });
    const def_acc = reactive({
      deviceName: '',
      headUrl: ''
    });
    const setting_fav_fc_switch = ref(false);
    const follower_columns = ref([]);
    const checkedItem = item => {
      if (item.checked) {
        uncommitted.value.push(item);
      } else {
        uncommitted.value = uncommitted.value.filter(f => {
          return f.userName !== item.userName;
        });
      }
      console.log(uncommitted.value);
    };
    const load_wx_acc_def = () => {
      return request.get({
        url: '/y/wx-account/default',
        params: {}
      }).then(r => {
        if (r.code == 0) {
          if (r.data) {
            console.log('r: ', r);
            Object.assign(def_acc, r.data);
            load_settings().then(r => {
              console.log('load_settings done! ', r);
              load_contracts();
            });
          } else {
            console.log('user default device not found');
          }
        } else if (r.code == 'ERR_BAD_REQUEST') {
          console.log('code != 0, ', r);
        } else {
          showNotify({
            type: 'danger',
            message: r.message
          });
        }
      });
    };
    const load_settings = () => {
      console.log('load_settings: ', def_acc);
      if (typeof def_acc.wxId == 'undefined') {
        return;
      }
      return request.get({
        url: `/y/user/settings/${def_acc.wxId}`,
        params: {}
      }).then(r => {
        if (r.code == 0) {
          console.log('r: ', r);
          Object.assign(settings, r.data);
          console.log('settings: ', settings);
          if (settings.follow) {
            // checked_follow
            let to_ids = settings.follow.split(',');
            // checked_follow[]
            for (let t in to_ids) {
              let i = to_ids[t];
              checked_follow[i] = true;
            }
            uncommitted.value.push(...settings.list);
          }
        } else {
          showNotify({
            type: 'danger',
            message: r.message
          });
        }
      });
    };
    const user_info = () => {
      request.post({
        url: `/y/user/info`,
        data: {}
      }).then(r => {
        console.log('user info: ', r);
        if (r.code == 0) {
          let d = r.data;
          def_acc.deviceName = d.username + "'s iPad";
        }
      });
    };
    const on_settings_t_update = val => {
      console.log('on_settings_t_update', val);
      return request.get({
        url: `/y/user/settings/t/${def_acc.wxId}`,
        params: {
          val: val
        }
      }).then(r => {
        if (r.code == 0) {
          console.log('r: ', r);
          Object.assign(settings, r.data);
        } else {
          showNotify({
            type: 'danger',
            message: r.message
          });
        }
      });
    };
    const load_contracts = () => {
      if (typeof def_acc.wxId == 'undefined') {
        return;
      }
      loading.value = true;
      return request.post({
        url: `/y/wx-contract/list/${def_acc.wxId}?page=${pageNumber.value}&size=10`,
        data: {
          keyword: search.keywords
        }
      }).then(r => {
        console.log('load contracts: ', r);
        loading.value = false;
        if (r.code == 0) {
          let d = r.data;
          if (d.pages == d.current) {
            finished.value = true;
          } else if (d.pages > d.current) {
            pageNumber.value += 1;
          }
          if (d.records.length == 0) {
            finished.value = true;
            return;
          }
          if (d.current === 1) {
            uncommitted.value.forEach(v => {
              v.checked = true;
              list.value.push(v);
            });
          }
          d.records.forEach(element => {
            if (checked_follow[element.userName]) {
              element.checked = true;
            }
            let notInUncommittedList = false;
            for (let i = 0; i < uncommitted.value.length; i++) {
              if (uncommitted.value[i].userName === element.userName) {
                notInUncommittedList = true;
                break;
              }
            }
            if (!notInUncommittedList) list.value.push(element);
          });
        }
      });
    };
    const set_follower = t => {
      console.log('set_follower: ', t);
      if (typeof def_acc.wxId == 'undefined') {
        return;
      }
      return request.get({
        url: `/y/user/settings/follow/${def_acc.wxId}`,
        params: {
          toWxId: t
        }
      }).then(r => {
        if (r.code == 0) {
          console.log('r: ', r);
          // Object.assign(settings, r.data)
          showConfirmDialog({
            title: '提示信息',
            message: '设置成功，是否返回？'
          }).then(() => {
            // on confirm
            router.go(-1);
          }).catch(() => {
            // on cancel
          });
        } else {
          //showNotify({ type: 'danger', message: r.message });
        }
      });
    };
    onMounted(() => {
      user_info();
      load_wx_acc_def();
    }); // onMounted

    return {
      search,
      load_contracts,
      pageNumber,
      list,
      loading,
      checkedItem,
      finished,
      follower_columns,
      follow,
      expire_time,
      goBack: () => {
        router.go(-1);
      },
      on_settings_t_update,
      load_settings,
      settings,
      def_acc,
      show_overlay,
      setting_fav_fc_switch,
      onSearch: () => {
        console.log('onSearch');
        pageNumber.value = 1;
        finished.value = false;
        list.value = [];
        load_contracts();
      },
      onCancel: () => {
        console.log('onCancel');
        search.keywords = '';
        pageNumber.value = 1;
        finished.value = false;
        list.value = [];
        load_contracts();
      },
      confirm: () => {
        console.log('confirm');
        let to_ids = [];
        for (let t in list.value) {
          let i = list.value[t];
          if (i.checked) {
            console.log('>>>> ', i.userName);
            to_ids.push(i.userName);
          }
        }
        if (to_ids.length == 0) {
          set_follower('');
        } else set_follower(to_ids.join(','));
      }
    };
  }
};