import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import request from './net/request'
import { ConfigProvider } from 'vant';
import 'vant/lib/index.css';

const app = createApp(App)

app.config.globalProperties.$request = request
app.use(router)
app.use(ConfigProvider);
app.mount('#app')