import { ref, reactive } from 'vue';
import Request from '@/net/request';
import { showNotify } from 'vant';
export default {
  setup() {
    const request = new Request();
    const show_overlay = ref(false);
    const show_qrcode = ref(false);
    const username = ref('');
    const password = ref('');
    let timeout = ref(0);
    const def_acc = reactive({
      deviceName: ''
    });
    let loginQrCode = ref("");
    const load_wx_acc_def = () => {
      return request.get({
        url: '/y/wx-account/default',
        params: {}
      }).then(r => {
        if (r && r.code == 0) {
          console.log('r: ', r);
          Object.assign(def_acc, r.data);
        } else {
          showNotify({
            type: 'danger',
            message: r.message
          });
        }
      });
    };
    load_wx_acc_def();
    const getLoginQrCode = () => {
      let params = {
        deviceId: def_acc.deviceId,
        deviceName: def_acc.deviceName,
        osModel: def_acc.osModel,
        acquireProxy: false
      };
      if (def_acc.id) {
        params['wxAccountId'] = def_acc.id;
      }
      // if(f.supplyProxy) {
      //     params['supplyProxy'] = f.supplyProxy
      //     params['host'] = f.host
      //     params['port'] = f.port
      //     params['username'] = f.username
      //     params['password'] = f.password
      // }
      console.log('params: ', params);
      return request.post({
        url: '/y/wx/login/qrCode',
        data: params
      });
    };
    const init = wxAccountId => {
      return request.get({
        url: `/y/wx/login/init/${wxAccountId}`,
        params: {
          hb: true
        }
      });
    };
    const checkLogin = () => {
      return request.get({
        url: `/y/wx/login/check/${def_acc.id}`
      }).then(r => {
        console.log('checkLogin r: ', r);
        if (r.data && r.data.WxId) {
          def_acc.wxId = r.data.WxId;
          console.log('wxId: ', def_acc.wxId);
          init(def_acc.wxId).then(r => {
            console.log('init result data: ', r.data);
          });
          showNotify({
            type: 'success',
            message: r.data.NickName + " 登录成功！"
          });
          show_qrcode.value = false;
          clearTimeout(timeout.value);
        } else if (r.data && r.data.status == 4) {
          showNotify({
            type: 'warning',
            message: "登录超时!"
          });
          show_qrcode.value = false;
          clearTimeout(timeout.value);
        } else if (!r.data) {
          showNotify({
            type: 'warning',
            message: "登录超时!"
          });
          show_qrcode.value = false;
          clearTimeout(timeout.value);
        } else {
          timeout.value = setTimeout(checkLogin, 5000);
        }
      });
    };
    const onSubmit = () => {
      console.log('submit called');
      getLoginQrCode().then(r => {
        console.log('res: ', r);
        loginQrCode.value = r.data.qrBase64;
        def_acc.uuid = r.data.uuid;
        def_acc.deviceId = r.data.deviceId;
        def_acc.id = r.data.wxAccountId;
        def_acc.qrExpireTime = r.data.qrExpireTime;
        show_qrcode.value = true;
        timeout.value = setTimeout(checkLogin, 5000);
      });
    };
    return {
      show_qrcode,
      def_acc,
      username,
      password,
      onSubmit,
      show_overlay,
      loginQrCode
    };
  }
};