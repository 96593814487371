import "core-js/modules/es.array.push.js";
import { onMounted, ref } from 'vue';
import Request from '@/net/request';
import { useRouter } from 'vue-router';
import { showNotify } from 'vant';
import WxUtil from "@/util/WxUtil";
export default {
  setup() {
    const request = new Request();
    const wxUtil = new WxUtil();
    const show_overlay = ref(false);
    const username = ref('');
    const password = ref('');
    const password_ = ref('');
    const yzmcode = ref('');
    const promotionCode = ref('');
    const agreement_check = ref(true);
    const router = useRouter();
    const time = ref(-1);
    const ShowDownTime = ref("发送验证码");
    const disableSmsBtn = ref(false);
    const timeRemaining = () => {
      setInterval(() => {
        time.value--;
        if (time.value < 1) {
          disableSmsBtn.value = false;
          clearInterval(timeRemaining);
          ShowDownTime.value = "发送验证码";
        } else {
          ShowDownTime.value = "(" + time.value + ")";
        }
      }, 1000);
    };
    const pCode = request.getQueryVariable("promotionCode");
    const localCode = request.getCookie("promotionCode");
    if (pCode) {
      promotionCode.value = pCode;
      request.setCookie("promotionCode", pCode);
    } else if (localCode) {
      promotionCode.value = localCode;
    }
    const sendSms = () => {
      time.value = 59;
      disableSmsBtn.value = true;
      request.post({
        url: '/n/app-api/rights/user/sendMobileCode?mobile=' + username.value,
        data: {
          mobile: username.value
        }
      }).then(r => {
        if (r.status == 0) {
          timeRemaining();
          showNotify({
            type: 'success',
            message: '验发送证码成功！'
          });
        } else {
          showNotify({
            type: 'danger',
            message: r.msg
          });
        }
      });
    };
    const signUp = param => {
      const openid = sessionStorage.getItem("openid");
      if (openid) {
        param.openid = openid;
        param.mobile = param.username;
        param.yzm = yzmcode.value;
        request.post({
          url: '/n/app-api/rights/user/sign-up-mobile',
          data: param
        }).then(r => {
          if (r.status == 0) {
            let data = r.data;
            console.log('data: ', data);
            router.push({
              path: "/login"
            });
            showNotify({
              type: 'success',
              message: '注册成功！'
            });
            request.get({
              url: '/y/openapi/loginByOpenid?openid=' + openid,
              data: {
                openid: openid
              }
            }).then(r => {
              if (r.code == 0) {
                let data = r.data;
                console.log('loginByOpenId setCookie v1 data: ', data);
                wxUtil.setCookie('token', data);
                sessionStorage.setItem('token', data);
                wxUtil.setCookie('retry', "2");
                request.setCookie("403", "2");
                router.push({
                  path: "/friendCycle"
                });
              } else {
                showNotify({
                  type: 'danger',
                  message: r.message
                });
              }
            });
          } else {
            showNotify({
              type: 'danger',
              message: r.msg
            });
          }
        });
      } else {
        showNotify({
          type: 'danger',
          message: "未获取openid！"
        });
        wxUtil.getOpenId({});
      }
    };
    const onSubmit = values => {
      console.log('submit', values);
      if (agreement_check.value) {
        signUp(values);
      } else {
        showNotify({
          type: 'danger',
          message: '请阅读并同意“车速摇用户账号服务协议、隐私协议”'
        });
      }
    };
    onMounted(() => {
      const openid = sessionStorage.getItem("openid");
      //showNotify({type: 'danger', message: 'openId:'+openid});
      if (!openid) {
        //showNotify({type: 'danger', message: '准备获取openid'});
        wxUtil.getOpenId({});
      }
    });
    return {
      promotionCode,
      agreement_check,
      timeRemaining,
      yzmcode,
      username,
      ShowDownTime,
      disableSmsBtn,
      sendSms,
      time,
      password,
      password_,
      onSubmit,
      show_overlay,
      back: () => {
        router.push({
          path: "/login"
        });
      }
    };
  }
};