import { onMounted } from 'vue';
import WxUtil from "@/util/WxUtil";
export default {
  setup() {
    const wxUtil = new WxUtil();
    onMounted(() => {
      wxUtil.pay();
    });
    return {};
  }
};