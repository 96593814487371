import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  const _component_van_search = _resolveComponent("van-search");
  const _component_van_col = _resolveComponent("van-col");
  const _component_van_row = _resolveComponent("van-row");
  const _component_van_checkbox = _resolveComponent("van-checkbox");
  const _component_van_cell = _resolveComponent("van-cell");
  const _component_van_list = _resolveComponent("van-list");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_nav_bar, {
    "left-text": "返回",
    title: "",
    "left-arrow": "",
    "right-text": "确认",
    onClickLeft: $setup.goBack,
    onClickRight: $setup.confirm
  }, null, 8, ["onClickLeft", "onClickRight"]), _createVNode(_component_van_row, null, {
    default: _withCtx(() => [_createVNode(_component_van_col, {
      span: "24"
    }, {
      default: _withCtx(() => [_createVNode(_component_van_search, {
        modelValue: $setup.search.keywords,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.search.keywords = $event),
        "show-action": "",
        placeholder: "请输入搜索关键词",
        onSearch: $setup.onSearch,
        onCancel: $setup.onCancel
      }, null, 8, ["modelValue", "onSearch", "onCancel"])]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_van_list, {
    loading: $setup.loading,
    "onUpdate:loading": _cache[1] || (_cache[1] = $event => $setup.loading = $event),
    finished: $setup.finished,
    "finished-text": "没有更多了",
    onLoad: $setup.load_contracts
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.list, item => {
      return _openBlock(), _createBlock(_component_van_cell, {
        key: item.id,
        title: item.nickName
      }, {
        "right-icon": _withCtx(() => [_createVNode(_component_van_checkbox, {
          modelValue: item.checked,
          "onUpdate:modelValue": $event => item.checked = $event,
          onClick: $event => $setup.checkedItem(item)
        }, null, 8, ["modelValue", "onUpdate:modelValue", "onClick"])]),
        _: 2
      }, 1032, ["title"]);
    }), 128))]),
    _: 1
  }, 8, ["loading", "finished", "onLoad"])], 64);
}