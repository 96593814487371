import "core-js/modules/es.array.push.js";
import { ref, reactive, onMounted } from 'vue';
import Request from '@/net/request';
import { closeToast, showFailToast, showImagePreview, showLoadingToast, showNotify } from "vant";
import WxUtil from "@/util/WxUtil";
import moment from "moment/moment";
export default {
  setup() {
    const wxUtil = new WxUtil();
    const list = ref([]);
    const telActions = ref([]);
    const showTels = ref(false);
    const loading = ref(false);
    const finished = ref(false);
    const showPay = ref(false);
    const pageNumber = ref(1);
    const follow = ref('');
    const request = new Request();
    const show_overlay = ref(false);
    const expire_time = ref(0);
    const index = ref(0);
    const shopname = ref("全国");
    const images = [];
    const columns = [{
      text: '全国',
      value: '0'
    }];
    const columnsPaiXu = [{
      text: '所有',
      value: ''
    }, {
      text: '大众',
      value: '大众'
    }, {
      text: '奔驰',
      value: '奔驰'
    }, {
      text: '宝马',
      value: '宝马'
    }];
    const fieldValue = ref('');
    const showPicker = ref(false);
    const showPickerPaiXu = ref(false);
    const search = reactive({
      keywords: ''
    });
    const onConfirm = ({
      selectedOptions
    }) => {
      showPicker.value = false;
      fieldValue.value = selectedOptions[0].value;
      shopname.value = selectedOptions[0].text;
    };
    const onConfirmPaiXu = ({
      selectedOptions
    }) => {
      showPickerPaiXu.value = false;
      fieldValue.value = selectedOptions[0].value;
      search.keywords = selectedOptions[0].value;
      onSearch();
    };
    const onChange = newIndex => {
      index.value = newIndex;
    };
    const settings = reactive({
      t: false,
      follow: undefined
    });
    const def_acc = reactive({
      deviceName: '',
      headUrl: ''
    });
    const setting_fav_fc_switch = ref(false);
    const follower_columns = ref([]);
    const load_timelines = () => {
      /*var a = {
        "data": {
          "records": [{
            "id": 60085,
            "wxId": "",
            "userId": 29,
            "xml": "",
            "parsedXmlObject": null,
            "sourceId": "14313235408624882266",
            "event": "sync",
            "username": "",
            "remark1": "https://owl-hl.oss-cn-shanghai.aliyuncs.com/icon.png",
            "remark2": "车速摇会员解锁",
            "tels": "13321686966",
            "thumbImgs": "[https://owl-hl.oss-cn-shanghai.aliyuncs.com/2024/01/26/14313235408624882266/314f5763-6c65-438c-aed5-dbb04a6f1a93.jpg, https://owl-hl.oss-cn-shanghai.aliyuncs.com/2024/01/26/14313235408624882266/686555e1-a2f7-45cf-858a-9204d3cd5266.jpg, https://owl-hl.oss-cn-shanghai.aliyuncs.com/2024/01/26/14313235408624882266/379c20e8-01f7-44cf-9c64-423c23aa0d57.jpg, https://owl-hl.oss-cn-shanghai.aliyuncs.com/2024/01/26/14313235408624882266/0c545b86-babd-41a7-81fe-3265cb90c01f.jpg, https://owl-hl.oss-cn-shanghai.aliyuncs.com/2024/01/26/14313235408624882266/b42ab291-cce2-4deb-a73b-b9287a21da41.jpg, https://owl-hl.oss-cn-shanghai.aliyuncs.com/2024/01/26/14313235408624882266/2e12163e-c02f-4a2f-b074-3298b706e3aa.jpg, https://owl-hl.oss-cn-shanghai.aliyuncs.com/2024/01/26/14313235408624882266/9320092b-4afb-46b0-afcd-07aedb2b11be.jpg, https://owl-hl.oss-cn-shanghai.aliyuncs.com/2024/01/26/14313235408624882266/c6b1660f-814c-4ba5-afd8-7610754364a5.jpg, https://owl-hl.oss-cn-shanghai.aliyuncs.com/2024/01/26/14313235408624882266/f94c4943-60fd-4310-bc34-e30b2aba12d0.jpg]",
            "generalImgs": "[https://owl-hl.oss-cn-shanghai.aliyuncs.com/2024/01/26/14313235408624882266/5a08527f-578b-49dd-bca9-0b5843111f10.jpg, https://owl-hl.oss-cn-shanghai.aliyuncs.com/2024/01/26/14313235408624882266/0555cf86-05a3-4455-b91d-04c587a12616.jpg, https://owl-hl.oss-cn-shanghai.aliyuncs.com/2024/01/26/14313235408624882266/06f5ff1d-6191-43cb-87ca-240bab5527f9.jpg, https://owl-hl.oss-cn-shanghai.aliyuncs.com/2024/01/26/14313235408624882266/7047d6d1-0f49-4d77-a254-d30e858d2d2c.jpg, https://owl-hl.oss-cn-shanghai.aliyuncs.com/2024/01/26/14313235408624882266/a7348173-5f87-4c3d-8b2c-2b0cbfb72c19.jpg, https://owl-hl.oss-cn-shanghai.aliyuncs.com/2024/01/26/14313235408624882266/fe92eee3-71fc-449a-8efb-db78a8904526.jpg, https://owl-hl.oss-cn-shanghai.aliyuncs.com/2024/01/26/14313235408624882266/cd7a66ab-85e7-47b1-8869-5d3cbe6f9772.jpg, https://owl-hl.oss-cn-shanghai.aliyuncs.com/2024/01/26/14313235408624882266/6cb8258c-810a-4afc-b531-a7c6e094d35d.jpg, https://owl-hl.oss-cn-shanghai.aliyuncs.com/2024/01/26/14313235408624882266/7ebab965-811b-4484-b9f3-939d74311ff1.jpg]",
            "contentDesc": "新上架☘️2015年2月本田雅阁2.4L豪华版 搭载地球梦发动机未代的自然吸气 一手车实表12万多公里 重点全车一个面喷漆 原厂2把智能锁匙 最漂亮的白色外观搭配黑内饰 超级极品车况……",
            "allSize": "[\"{\\\"width\\\":1440,\\\"height\\\":1080,\\\"totalSize\\\":84448}\",\"{\\\"width\\\":1440,\\\"height\\\":1080,\\\"totalSize\\\":95039}\",\"{\\\"width\\\":1440,\\\"height\\\":1080,\\\"totalSize\\\":116655}\",\"{\\\"width\\\":1440,\\\"height\\\":1080,\\\"totalSize\\\":87601}\",\"{\\\"width\\\":1440,\\\"height\\\":1080,\\\"totalSize\\\":66526}\",\"{\\\"width\\\":1440,\\\"height\\\":1080,\\\"totalSize\\\":86522}\",\"{\\\"width\\\":1440,\\\"height\\\":1080,\\\"totalSize\\\":116070}\",\"{\\\"width\\\":1440,\\\"height\\\":1080,\\\"totalSize\\\":130407}\",\"{\\\"width\\\":1080,\\\"height\\\":1440,\\\"totalSize\\\":90095}\"]",
            "processed": 1,
            "createTime": "2024-01-26 20:10:02",
            "updateTime": "2024-01-26 20:11:33",
            "del": false,
            "col": false
          }],
          "total": 7526,
          "size": 10,
          "current": 1,
          "orders": [],
          "optimizeCountSql": true,
          "searchCount": true,
          "maxLimit": null,
          "countId": null,
          "pages": 753
        }, "code": 0, "message": "success"
      }
      a.data.records.forEach(element => {
         const item = {id: element.id, wxId: element.wxId}
        item.medias = element.generalImgs.replace("[", "").replace("]", "").split(",");
        item.media = item.medias[0]
        item.wxId = element.username
            item.hasTel=element.hasTel
        item.remark1 = element.remark1
        item.remark2 = element.remark2
         item.createTime = formatValur1(element.createTime)
        item.contentDesc = element.contentDesc
        /!*item.colBtn=element.col?"取消收藏":"收藏数据"*!/
        item.col = !element.col
        list.value.push(item)
        console.log(item, "new")
      });
      console.log(wxUtil)
      console.log(list.value)
      console.log(moment)*/

      loading.value = true;
      const now = moment();
      const formattedToday = now.format('YYYY-MM-DD HH:mm:ss');
      const nextWeek = now.subtract(3, 'day');
      const formattedBefore = nextWeek.format('YYYY-MM-DD HH:mm:ss');
      console.log(formattedBefore, formattedToday);
      return request.post({
        url: `/y/openapi/list3?page=${pageNumber.value}&size=10`,
        data: {
          keyword: search.keywords,
          wxId: def_acc.wxId
        }
      }).then(r => {
        console.log('load friend: ', r);
        loading.value = false;
        if (r.code == 0) {
          let d = r.data;
          if (d.pages == d.current) {
            finished.value = true;
          } else if (d.pages > d.current) {
            pageNumber.value += 1;
            if (pageNumber.value >= 5) {
              const ltoken = wxUtil.getCookie('token');
              if (!ltoken) location.href = "/sign-up";
            }
          }
          if (d.records.length == 0) {
            finished.value = true;
            return;
          }
          d.records.forEach(element => {
            /*if (checked_follow[element.userName]) {
              element.checked = true
            }*/

            const item = {
              id: element.id,
              wxId: element.wxId
            };
            item.medias = element.generalImgs.replace("[", "").replace("]", "").split(",");
            item.media = item.medias[0];
            item.wxId = element.username;
            item.remark1 = element.remark1;
            item.remark2 = element.remark2;
            item.hasTel = element.hasTel;
            item.createTime = formatValur1(element.createTime);
            item.contentDesc = element.contentDesc;
            item.col = element.col;
            item.colBtn = element.col ? "取消收藏" : "收藏数据";
            list.value.push(item);
            console.log(item, "new");
          });
          /*d.records.forEach(element => {
            if (checked_follow[element.userName]) {
              element.checked = true
            }
            list.value.push(element)
          });*/
        }
      });
    };
    const set_follower = t => {
      console.log('set_follower: ', t);
      if (typeof def_acc.wxId == 'undefined') {
        return;
      }
      return request.get({
        url: `/y/user/settings/follow/${def_acc.wxId}`,
        params: {
          toWxId: t
        }
      }).then(r => {
        if (r.code == 0) {
          console.log('r: ', r);
          // Object.assign(settings, r.data)
        } else {
          //showNotify({ type: 'danger', message: r.message });
        }
      });
    };
    const copyContent = url => {
      console.log(url);
      var textArea = document.createElement("textarea");
      textArea.value = url;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      showNotify({
        type: 'success',
        message: "已复制到剪切板"
      });
      //showNotify({type: 'danger', message: "已复制到剪切板"});
    };
    const showToast = duration => {
      return showLoadingToast({
        message: '加载中...。',
        duration: duration,
        forbidClick: true
      });
    };
    const col = item => {
      showToast();
      return request.post({
        url: `/y/user/col/return?timelineId=` + item.id
      }).then(r => {
        closeToast();
        if (r.code == 0) {
          if (r.data === "1") {
            item.col = true;
          } else if (r.data === "2") {
            item.col = false;
          }
        } else {
          showNotify({
            type: 'danger',
            message: r.message
          });
        }
      });
    };
    const onTelSelect = item => {
      showTels.value = false;
      window.location.href = 'tel://' + item.name;
    };
    const lianxi = item => {
      //sessionStorage.setItem('token', "eyJhbGciOiJIUzI1NiJ9.eyJpZCI6MSwic3ViIjoiYWRtaW4iLCJpYXQiOjE3MDMzNDc0NjcsImV4cCI6MTcwMzQzMzg2N30.AvBpUyfdMCe-TRNn_knrenuVW22kOBhNRkIRjEHtKRU")
      console.log(item);
      showToast(0);
      copyContent(item.contentDesc);
      request.post({
        url: '/y/openapi/dy/buildsdk',
        data: {
          imageUrlList: item.medias,
          hasTagUrlList: ["快摇二手车", "二手车商直卖", "二手车帮买"],
          "title": item.contentDesc
        }
      }).then(r => {
        closeToast();
        window.location.href = r.data;
        showNotify({
          type: 'danger',
          message: r.message
        });
      });
    };
    const getTels = item => {
      //sessionStorage.setItem('token', "eyJhbGciOiJIUzI1NiJ9.eyJpZCI6MSwic3ViIjoiYWRtaW4iLCJpYXQiOjE3MDMzNDc0NjcsImV4cCI6MTcwMzQzMzg2N30.AvBpUyfdMCe-TRNn_knrenuVW22kOBhNRkIRjEHtKRU")
      console.log(item);
      showToast(0);
      return request.post({
        url: `/y/timeline/getTels?id=` + item.id,
        data: {
          id: item.id
        }
      }).then(r => {
        closeToast();
        if (r.code == 0) {
          telActions.value = [];
          if (r.data.tels.length > 0) {
            r.data.tels.forEach(v => {
              telActions.value.push({
                name: v
              });
            });
            showTels.value = true;
          } else {
            showFailToast('未找到电话联系方式');
          }

          // Object.assign(settings, r.data)
        } else if (r.code === 2) {
          showPay.value = true;
        } else {
          showNotify({
            type: 'danger',
            message: r.message
          });
        }
      });
    };
    const share = item => {
      //sessionStorage.setItem('token', "eyJhbGciOiJIUzI1NiJ9.eyJpZCI6MSwic3ViIjoiYWRtaW4iLCJpYXQiOjE3MDMzNDc0NjcsImV4cCI6MTcwMzQzMzg2N30.AvBpUyfdMCe-TRNn_knrenuVW22kOBhNRkIRjEHtKRU")
      console.log(item);
      showToast(0);
      return request.post({
        url: `/y/wx/friend-circle/shareFriend/` + item.id,
        data: {
          id: item.id
        }
      }).then(r => {
        closeToast();
        if (r.code == 0) {
          console.log('r: ', r);
          showNotify({
            type: 'danger',
            message: "操作成功"
          });
          // Object.assign(settings, r.data)
        } else if (r.code === 2) {
          showPay.value = true;
        } else {
          showNotify({
            type: 'danger',
            message: r.message
          });
        }
      });
    };
    const onSearch = () => {
      console.log('onSearch');
      pageNumber.value = 1;
      finished.value = false;
      list.value = [];
      load_timelines();
    };
    const topay = (action, done) => {
      if (action === "confirm") {
        location.href = "/pay/";
        return done(false);
      } else {
        showPay.value = false;
        return done(false);
      }
    };
    function formatValur1(expire) {
      const now = new Date();
      const date1 = new Date(expire); // 指定日期字符串

      // 计算时会发生隐式转换，调用valueOf()方法，转化成时间戳的形式
      var days = (now - date1) / 1000 / 3600 / 24;
      // 下面都是简单的数学计算
      var day = Math.floor(days);
      var hours = (days - day) * 24;
      var hour = Math.floor(hours);
      var minutes = (hours - hour) * 60;
      var minute = Math.floor(minutes);
      var seconds = (minutes - minute) * 60;
      var second = Math.floor(seconds);
      var back = '剩余时间：' + day + '天' + hour + '小时' + minute + '分钟' + second + '秒';
      console.log(back);
      if (day === 0 && hour === 0) {
        return minute + "分钟前";
      }
      if (day === 0) {
        return hour + "小时前";
      }
      return day > 7 ? "7天以前" : day + "天前";
    }
    const updateItem = item => {
      location.href = "/detail?id=" + item.id;
    };
    onMounted(() => {
      new WxUtil().updThreePoint2();
      /*showConfirmDialog({
        title: '联系方式',
        message: setRender()
      })
          .then(() => {
           })
          .catch(() => {
            // on cancel
          });*/
      /*telActions.value=["18217626159"]
      showTels.value=true*/
      load_timelines();
    }); // onMounted

    return {
      updateItem,
      columns,
      columnsPaiXu,
      onConfirm,
      telActions,
      showTels,
      lianxi,
      getTels,
      onTelSelect,
      onConfirmPaiXu,
      fieldValue,
      showPicker,
      showPickerPaiXu,
      topay,
      share,
      col,
      images,
      shopname,
      index,
      onChange,
      search,
      load_timelines,
      pageNumber,
      list,
      loading,
      finished,
      follower_columns,
      follow,
      clickCopy: url => {
        console.log(url);
        var textArea = document.createElement("textarea");
        textArea.value = url;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
        alert("已复制到剪切板");
      },
      clickShowImgs: (item, showIndex) => {
        console.log(item.medias);
        /*images.splice(0, images.length);
        images.push(...item.medias)
        show.value = true*/
        showImagePreview({
          images: item.medias,
          startPosition: showIndex
        });

        /*router.push("/pay/?id=" + item.id)*/
      },
      expire_time,
      goBack: () => {},
      showPay,
      settings,
      def_acc,
      show_overlay,
      setting_fav_fc_switch,
      onSearch,
      onCancel: () => {
        console.log('onCancel');
        search.keywords = '';
        pageNumber.value = 1;
        finished.value = false;
        list.value = [];
        load_timelines();
      },
      confirm: () => {
        console.log('confirm');
        let to_ids = [];
        for (let t in list.value) {
          let i = list.value[t];
          if (i.checked) {
            console.log('>>>> ', i.userName);
            to_ids.push(i.userName);
          }
        }
        if (to_ids.length == 0) {
          set_follower('');
        } else set_follower(to_ids.join(','));
      }
    };
  }
};