import { onMounted, ref } from "vue";
export default {
  setup() {
    const gid = ref(0);
    onMounted(() => {
      /*const wxUtil=new WxUtil()
      const id=wxUtil.getQueryVariable("id")
      gid.value=id
      wxUtil.updThreePoint(id,"啊啊啊啊啊啊啊啊啊啊...", "啊啊啊啊啊啊啊啊啊啊...","https://owl-hl.oss-cn-shanghai.aliyuncs.com/2024/03/18/timeline/72d10559-f61d-4909-8eb4-11d6af0812f3.jpg");
      showSuccessToast('更新成功2');*/

      //new WxUtil().updThreePoint2();
      /*showConfirmDialog({
        title: '联系方式',
        message: setRender()
      })
          .then(() => {
           })
          .catch(() => {
            // on cancel
          });*/
      /*telActions.value=["18217626159"]
      showTels.value=true*/
    });
    return {
      gid
    };
  }
};