import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  style: {
    "margin": "16px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_field = _resolveComponent("van-field");
  const _component_van_cell_group = _resolveComponent("van-cell-group");
  const _component_van_button = _resolveComponent("van-button");
  const _component_van_form = _resolveComponent("van-form");
  const _component_van_image = _resolveComponent("van-image");
  const _component_van_popup = _resolveComponent("van-popup");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_form, {
    onSubmit: $setup.onSubmit
  }, {
    default: _withCtx(() => [_createVNode(_component_van_cell_group, {
      inset: ""
    }, {
      default: _withCtx(() => [_createVNode(_component_van_field, {
        modelValue: $setup.def_acc.deviceName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.def_acc.deviceName = $event),
        name: "deviceName",
        label: "设备",
        placeholder: "xxx's iPad",
        rules: [{
          required: true,
          message: '请填写设备名称'
        }]
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_van_button, {
      round: "",
      block: "",
      type: "primary",
      "native-type": "submit"
    }, {
      default: _withCtx(() => [_createTextVNode(" 获取登录二维码 ")]),
      _: 1
    })])]),
    _: 1
  }, 8, ["onSubmit"]), _createVNode(_component_van_popup, {
    show: $setup.show_qrcode,
    "onUpdate:show": _cache[1] || (_cache[1] = $event => $setup.show_qrcode = $event)
  }, {
    default: _withCtx(() => [_createVNode(_component_van_image, {
      width: "20rem",
      fit: "fill",
      src: $setup.loginQrCode
    }, null, 8, ["src"])]),
    _: 1
  }, 8, ["show"])], 64);
}